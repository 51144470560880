import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import ReactGA from 'react-ga4'
import { PersistGate } from "redux-persist/integration/react"
import { QueryClientProvider, QueryClient } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import * as serviceWorker from "./serviceWorker"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { ChakraProvider } from "@chakra-ui/react"
import Fonts from "./component/Fonts/Fonts"
import { measurementID } from "helper/ga"

// Load theme
import theme from "./theme/index"

// Load store
import { store, persistor } from "./helper/store"

// Load entry point
//import App from "./App"
import Routing from "./component/Router/Routing"

/**
 * To start MSW run `npm run start-mock`
 */
if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK === "true") {
  const { worker } = require("./mswSetup/browser")
  worker.start()
}

/**
 * Activate sentry in production
 */
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_ENV === 'production') {
  Sentry.init({
    dsn: "https://ec7427e4ecea472cacf58e9b08dff5ee@o4504917164228608.ingest.sentry.io/4504921298960384",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.10,
    release: "lottery-fe@1.0.1", // we need to automate this on build/release
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // Ignore Turnstile errors
      /TurnstileError: \[Cloudflare Turnstile\] Error: 300\d{3}/, // Matches 300xxx where xxx is any number
      "TurnstileError",
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ]
  })

  ReactGA.initialize(measurementID)
}


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      // globally default time to 30 seconds
      staleTime: 1000 * 30,
    },
    mutations: {
      retry: false,
    },
  },
})

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <PersistGate persistor={persistor}>
          <React.StrictMode>
            <ChakraProvider theme={theme}>
              <Fonts />
              <Routing />
            </ChakraProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </React.StrictMode>
        </PersistGate>
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>
)

const container = document.getElementById("root")
const root = createRoot(container)
root.render(app)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
